/* eslint-disable no-unused-vars */
import React, { createContext, ReactNode, useContext, useReducer } from 'react';
import { PDFLicense } from 'types/pricing';
import AppReducer from './appReducer';

export interface SelectedPriceOption {
  1: PDFLicense;
  2: PDFLicense;
  3: PDFLicense;
}

const INITIAL_MOCK: SelectedPriceOption = {
  1: {
    id: 1,
    price: '',
    volume_id: 'user_1',
    label: '1 User',
  },
  2: {
    id: 2,
    price: '',
    volume_id: 'user_1',
    label: '1 User',
  },
  3: {
    id: 3,
    price: '',
    volume_id: 'user_1',
    label: '1 User',
  },
};

interface IState {
  faqsDropDown: number[];
  showAllFAQs: boolean;
  customisePlans: boolean;
  allPlanFeatures: any[];
  pricingTable: string;
  setFAQsDropDown: (_props: number[]) => void;
  setShowAllFAQs: (props: boolean) => void;
  setCustomisePlan: (props: boolean) => void;
  setAllPlanFeatures: (props: any) => void;
  setPricingTable: (props: string) => void;
  selectedPriceOption: SelectedPriceOption;
  setSelectedPriceOption: (props: SelectedPriceOption) => void;
  isMobileSectionSticky: boolean;
  setIsMobileSectionSticky: (props: boolean) => void;
}

const initialState = {
  faqsDropDown: [],
  showAllFAQs: false,
  customisePlans: false,
  allPlanFeatures: [],
  pricingTable: '',
  setFAQsDropDown: (_props: number[]) => {},
  setShowAllFAQs: (props: boolean) => {},
  setCustomisePlan: (props: boolean) => {},
  setAllPlanFeatures: (props: any) => {},
  setPricingTable: (props: string) => {},
  selectedPriceOption: INITIAL_MOCK,
  setSelectedPriceOption: (props: SelectedPriceOption) => {},
  isMobileSectionSticky: false,
  setIsMobileSectionSticky: (props: boolean) => {},
};
export const GlobalContext = createContext<IState>(initialState);
export function GlobalProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  function setFAQsDropDown(faqsDropDown: number[]) {
    dispatch({ type: 'SET_FAQS_DROP_DOWNS', payload: faqsDropDown });
  }
  function setShowAllFAQs(showAllFAQs: boolean) {
    dispatch({ type: 'SET_SHOW_ALL_FAQS', payload: showAllFAQs });
  }
  function setCustomisePlan(customisePlans: boolean) {
    dispatch({ type: 'SET_CUSTOMISE_PLANS', payload: customisePlans });
  }
  function setAllPlanFeatures(allPlanFeatures: any) {
    dispatch({ type: 'SET_PLAN_FEATURES', payload: allPlanFeatures });
  }
  function setPricingTable(pricingTable: any) {
    dispatch({ type: 'SET_PRICING_TABLE', payload: pricingTable });
  }
  function setSelectedPriceOption(selectedPriceOption: SelectedPriceOption) {
    dispatch({
      type: 'SET_SELECTED_PRICE_OPTION',
      payload: selectedPriceOption,
    });
  }
  function setIsMobileSectionSticky(isMobileSectionSticky: boolean) {
    dispatch({
      type: 'SET_IS_MOBILE_SECTION_STICKY',
      payload: isMobileSectionSticky,
    });
  }
  return (
    <GlobalContext.Provider
      value={{
        faqsDropDown: state.faqsDropDown,
        setFAQsDropDown,
        showAllFAQs: state.showAllFAQs,
        setShowAllFAQs,
        customisePlans: state.customisePlans,
        setCustomisePlan,
        allPlanFeatures: state.allPlanFeatures,
        setAllPlanFeatures,
        pricingTable: state.pricingTable,
        setPricingTable,
        selectedPriceOption: state.selectedPriceOption,
        setSelectedPriceOption,
        isMobileSectionSticky: state.isMobileSectionSticky,
        setIsMobileSectionSticky,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export const useGlobalState = () => useContext(GlobalContext);
