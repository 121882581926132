import { createContext, Dispatch, SetStateAction } from 'react';

interface IHighlightContext {
  allow: boolean;
  change: Dispatch<SetStateAction<boolean>>;
}

const HighlightContext = createContext<IHighlightContext>({
  allow: true,
  change: () => {},
});

export default HighlightContext;
