import { useTheme as useThemeContext } from 'next-themes';
import { useEffect } from 'react';

const useTheme = () => {
  const { theme, systemTheme } = useThemeContext();

  useEffect(() => {
    if (theme === 'system') {
      localStorage.setItem('theme', systemTheme === 'dark' ? 'dark' : 'light');
    }
  }, [systemTheme]);
  if (theme === 'system') {
    return systemTheme === 'dark' ? 'dark' : 'light';
  }
  return theme === 'dark' ? 'dark' : 'light';
};

export default useTheme;
