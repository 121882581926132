import { createContext, Dispatch } from 'react';

interface ISMSAuth {
  token: { access: string; refresh: string };
  setToken: Dispatch<{ access: string; refresh: string }>;
}

const SMSAuthContext = createContext<ISMSAuth>({
  token: { access: '', refresh: '' },
  setToken: () => {},
});

export default SMSAuthContext;
