import { createContext, Dispatch, SetStateAction } from 'react';
import { ISearchResult } from 'types/common';

interface ISearchContext {
  searchResults: ISearchResult;
  setSearchResults: Dispatch<SetStateAction<ISearchResult>>;
}

const SearchContext = createContext<ISearchContext>({
  searchResults: { value: '', results: [] },
  setSearchResults: () => {},
});

export default SearchContext;
