import { createContext, Dispatch, SetStateAction } from 'react';

interface IUserContext {
  user: string | null | false;
  setUser: Dispatch<SetStateAction<string | null | false>>;
}

const UserContext = createContext<IUserContext>({
  user: null,
  setUser: () => {},
});

export default UserContext;
