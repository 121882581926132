/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ThemeProvider } from 'next-themes';
import { AppProps } from 'next/app';
import { ISearchResult } from 'types/common';
import SMSAuthContext from '@context/userSMSAuth';
import NoCookiePopUp from '@components/NoCookiePopUp';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GlobalProvider } from '@context/globalState';
import SearchContext from '@context/SearchContext';
import AOS from 'aos';
import '../styles/globals.css';
import 'aos/dist/aos.css';
import 'react-tooltip/dist/react-tooltip.css';
import '../styles/medium-zoom.css';
import 'tippy.js/dist/tippy.css';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from '@components/ErrorBoundary';
import TickerContext from '@context/ReRenderContext';
import HighlightContext from '@context/TextHighlighterContext';
import UseHighlightText from '@utils/useHighlightText';
import { ToastContainer } from 'react-toastify';
import { init } from '@socialgouv/matomo-next';

import {
  WEBSITE_SECRET,
  INTERCOM_APP_ID,
  INTERCOM_API_BASE,
  isCyprus,
} from '@config/index';
import { addLangToHtml, validateToken } from '@utils/index';
import UserContext from '@context/userContext';
import { IntercomProvider } from 'react-use-intercom';

import IntercomLauncher from '@components/IntercomLauncher';
import SMSContext from '@context/SMSContext';
import { MediaQueryProvider } from '@hooks/useMediaQuery';
import { clearCachedMedia, getInternalData } from '@config/api';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 604800,
      refetchOnReconnect: 'always',
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [token, setToken] = useState({ access: '', refresh: '' });
  const [smsEmail, setSmsEmail] = useState('');
  const [qaoEmail, setQaoEmail] = useState('');
  const [planDetails, setPlanDetails] = useState({});

  const [reRender, setReRender] = useState(false);
  const [secret, setSecret] = useState<null | 'none' | boolean>(null);
  const [secretToken, setSecretToken] = useState('');
  const [searchResults, setSearchResults] = useState<ISearchResult>({
    value: '',
    results: [],
  });
  const [allowSelect, setAllowSelect] = useState(true);
  const [user, setUser] = useState<string | null | false>(null);
  const [tick, nextTick] = useState(false);
  const [isClient, setIsClient] = useState(false);

  const setupMatomo = async () => {
    try {
      const configData = await getInternalData('/config')
        .then((res) => res.data)
        .catch(() => ({}));
      init({
        url: configData.mu,
        siteId: configData.mi,
        disableCookies: true,
      });
    } catch (err) {
      // Empty
    }
  };
  const handleRouteChange = () => {
    setReRender(false);
    setAllowSelect(true);
  };
  const removeSelection = () => {
    if (router.asPath?.includes('search')) {
      setAllowSelect(true);
      return;
    }
    setAllowSelect(true);
  };
  const checkAndAuthenticateUser = async () => {
    const hasToken = localStorage.getItem('auth-jwt');
    if (hasToken?.length) {
      const _token = await validateToken(hasToken);
      if (_token) {
        setUser(hasToken);
      } else {
        localStorage.removeItem('auth-jwt');
        setUser(false);
      }
    } else {
      setUser(false);
    }
  };
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    setIsClient(true);
    checkAndAuthenticateUser();
    const secretURL = new URLSearchParams(window.location.search).get('secret');
    let hasSecret = localStorage.getItem('token') === WEBSITE_SECRET;
    if (secretURL === WEBSITE_SECRET) {
      localStorage.setItem('token', secretURL);
      hasSecret = true;
    }
    setSecret(hasSecret || 'none');
    nextTick(!tick);
    try {
      setupMatomo();
      clearCachedMedia();
      addLangToHtml();
    } catch (error) {
      /* empty */
    }
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);
  useEffect(() => {
    if (allowSelect) {
      window.addEventListener('click', removeSelection);
    }
    return () => {
      window.removeEventListener('click', removeSelection);
    };
  }, [allowSelect, router]);
  const submitToken = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      secretToken === WEBSITE_SECRET ||
      secretToken === 'totMAnYbANTalptivIlATERseURINDmOuder'
    ) {
      localStorage.setItem('token', secretToken);
      window.location.reload();
    }
  };

  const bypassPage =
    router.pathname === '/action/[slug]' ||
    router.pathname === '/[language]/error' ||
    isCyprus ||
    router.pathname === '/boot' ||
    router.pathname === '/[language]/b';
  if (!secret && !bypassPage) {
    return null;
  }

  if (secret === 'none' && !bypassPage) {
    return (
      <>
        <Head>
          <title>QuodArca</title>
        </Head>
        <div className="bg-white w-full min-h-screen flex justify-center items-center">
          <form
            onSubmit={submitToken}
            className="w-full flex flex-col justify-center items-center"
          >
            <input
              value={secretToken}
              onChange={(e) => setSecretToken(e.target.value)}
              required
              className="w-1/2 p-4 bg-white border border-black"
            />
            <button
              type="submit"
              value=""
              className="w-[200px] mt-4 border border-black"
            >
              Submit
            </button>
          </form>
        </div>
      </>
    );
  }

  if (!isClient) {
    return null;
  }

  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
        <meta
          httpEquiv="Content-Security-Policy"
          content="upgrade-insecure-requests"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/favicons/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/favicons/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/favicons/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/favicons/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/favicons/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/favicons/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/favicons/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/favicons/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicons/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicons/manifest.json" />
      </Head>
      <ErrorBoundary FallbackComponent>
        <MediaQueryProvider>
          <ToastContainer />
          <IntercomProvider
            autoBoot
            appId={INTERCOM_APP_ID}
            apiBase={INTERCOM_API_BASE}
            autoBootProps={{
              hideDefaultLauncher: true,
              customLauncherSelector: '#intercom-launcher',
            }}
          >
            <QueryClientProvider client={queryClient}>
              <GlobalProvider>
                <SMSContext.Provider
                  value={{
                    smsEmail,
                    setSmsEmail,
                    qaoEmail,
                    setQaoEmail,
                    setToken,
                    token,
                    planDetails,
                    setPlanDetails,
                  }}
                >
                  <SMSAuthContext.Provider value={{ token, setToken }}>
                    <HighlightContext.Provider
                      value={{ allow: allowSelect, change: setAllowSelect }}
                    >
                      <SearchContext.Provider
                        value={{ searchResults, setSearchResults }}
                      >
                        <UserContext.Provider value={{ user, setUser }}>
                          <TickerContext.Provider
                            value={{ tick, nextTick, reRender, setReRender }}
                          >
                            <ThemeProvider
                              attribute="class"
                              enableSystem
                              storageKey="theme"
                            >
                              <Component {...pageProps} />
                              <NoCookiePopUp />
                              <UseHighlightText
                                setReRender={setReRender}
                                reRender={reRender}
                              />
                              <IntercomLauncher />
                            </ThemeProvider>
                          </TickerContext.Provider>
                        </UserContext.Provider>
                      </SearchContext.Provider>
                    </HighlightContext.Provider>
                  </SMSAuthContext.Provider>
                </SMSContext.Provider>
              </GlobalProvider>
            </QueryClientProvider>
          </IntercomProvider>
        </MediaQueryProvider>
      </ErrorBoundary>
    </>
  );
}

export default MyApp;
