import { createContext, Dispatch } from 'react';

interface ISMSContext {
  token: { access: string; refresh: string };
  setToken: Dispatch<{ access: string; refresh: string }>;
  smsEmail: string;
  setSmsEmail: Dispatch<string>;
  qaoEmail: string;
  setQaoEmail: Dispatch<string>;
  planDetails: any;
  setPlanDetails: Dispatch<any>;
}

const SMSContext = createContext<ISMSContext>({
  token: { access: '', refresh: '' },
  setToken: () => {},
  smsEmail: '',
  setSmsEmail: () => {},
  qaoEmail: '',
  setQaoEmail: () => {},
  planDetails: {},
  setPlanDetails: () => {},
});

export default SMSContext;
