import { IReducerType } from 'types/context';

const Reducers = (state: any, action: IReducerType) => {
  switch (action.type) {
    case 'SET_FAQS_DROP_DOWNS': {
      return {
        ...state,
        faqsDropDown: action.payload,
      };
    }
    case 'SET_SHOW_ALL_FAQS': {
      return {
        ...state,
        showAllFAQs: action.payload,
      };
    }
    case 'SET_CUSTOMISE_PLANS': {
      return {
        ...state,
        customisePlans: action.payload,
      };
    }
    case 'SET_PLAN_FEATURES': {
      return {
        ...state,
        allPlanFeatures: action.payload,
      };
    }
    case 'SET_PRICING_TABLE': {
      return {
        ...state,
        pricingTable: action.payload,
      };
    }
    case 'SET_SELECTED_PRICE_OPTION': {
      return {
        ...state,
        selectedPriceOption: action.payload,
      };
    }
    case 'SET_IS_MOBILE_SECTION_STICKY': {
      return {
        ...state,
        isMobileSectionSticky: action.payload,
      };
    }
    default:
      return state;
  }
};
export default Reducers;
