import React, {
  useEffect,
  useState,
  createContext,
  ReactNode,
  useContext,
} from 'react';
import { ResponsiveObject } from 'types/strapi/language';

export type ScreenType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export const MediaQueryContext = createContext<{
  screen: ScreenType;
  // eslint-disable-next-line no-unused-vars
  chooseResponsiveString: (responsiveObject?: ResponsiveObject) => string;
}>({
  screen: '2xl',
  chooseResponsiveString: () => '',
});

export function MediaQueryProvider({ children }: { children: ReactNode }) {
  const [screen, setScreen] = useState<ScreenType>('2xl');

  function chooseResponsiveString(responsiveObject?: ResponsiveObject) {
    const isValidObject = !!Object.keys(responsiveObject || {}).length;
    if (!isValidObject || !responsiveObject) return '';
    const defaultString = responsiveObject.desktop;

    if (screen === 'xs') return responsiveObject.mobile || defaultString;
    if (screen === 'md' || screen === 'sm')
      return responsiveObject.small_tablet || defaultString;
    if (screen === 'lg') return responsiveObject.large_tablet || defaultString;
    return defaultString;
  }

  const handleResize = () => {
    if (window.innerWidth >= 1360) {
      setScreen('2xl');
    } else if (window.innerWidth >= 1280) {
      setScreen('xl');
    } else if (window.innerWidth >= 1025) {
      setScreen('lg');
    } else if (window.innerWidth >= 768) {
      setScreen('md');
    } else if (window.innerWidth >= 641) {
      setScreen('sm');
    } else {
      setScreen('xs');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <MediaQueryContext.Provider value={{ screen, chooseResponsiveString }}>
      {children}
    </MediaQueryContext.Provider>
  );
}

export const useMediaQuery = () => {
  const { screen, chooseResponsiveString } = useContext(MediaQueryContext);
  return {
    screen,
    chooseResponsiveString,
    isMobile: screen === 'xs' || screen === 'sm',
  };
};
