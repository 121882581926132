import { createContext, Dispatch, SetStateAction } from 'react';

interface ITicketContext {
  tick: boolean;
  nextTick: Dispatch<SetStateAction<boolean>>;
  reRender: boolean;
  setReRender: Dispatch<SetStateAction<boolean>>;
}

const TickerContext = createContext<ITicketContext>({
  tick: false,
  nextTick: () => {},
  reRender: false,
  setReRender: () => {},
});

export default TickerContext;
